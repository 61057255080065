import Link from "next/link";
import React, { useRef, useState } from "react";
import { useClickAway, useWindowScroll } from "react-use";
import { useUser } from "@auth0/nextjs-auth0";

type HeaderProps = { bg?: boolean };

const Header: React.FC<HeaderProps> = ({ bg = false }) => {
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { y } = useWindowScroll();
  const { user, error, isLoading } = useUser();

  useClickAway(ref, () => {
    setMenuOpen(false);
  });

  const filled = bg || y > 70;

  return (
    <div ref={ref} className="fixed w-full z-20">
      <nav className={`bg-white ${filled ? "" : "md:bg-transparent"}`}>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-12">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${menuOpen ? "hidden" : "block"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg
                  className={`${menuOpen ? "block" : "hidden"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <div className="lg:hidden flex items-center space-x-2">
                  <img className="h-8 w-auto" src="/img/logo-sm.png" alt="Workflow" />
                  <span className="font-semibold">Tierversand24</span>
                </div>
                <img className="hidden lg:block h-8 w-auto" src="/img/logo-sm.png" alt="Workflow" />
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/*<!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->*/}
                  <Link href="/">
                    <a
                      className={`${
                        filled ? "text-gray-700" : "text-white"
                      } px-3 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                    >
                      Übersicht
                    </a>
                  </Link>
                  <Link href="/sendungsverfolgung">
                    <a
                      className={`${
                        filled ? "text-gray-700" : "text-white"
                      } px-3 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                    >
                      Sendungsverfolgung
                    </a>
                  </Link>
                  {!user ? (
                    <a
                      href="/api/auth/login"
                      className={`${
                        filled ? "text-gray-700" : "text-white"
                      } px-3 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                    >
                      Login
                    </a>
                  ) : !isLoading && !error ? (
                    <>
                      <div className={`${filled ? "border-black" : "border-white"} border-l-2 my-2`} />
                      <Link href="/shipments">
                        <a
                          className={`${
                            filled ? "text-gray-700" : "text-white"
                          } px-1 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                        >
                          Meine Transporte
                        </a>
                      </Link>
                      {/*<Link href="/contacts">
                        <a
                          className={`${filled ? 'text-gray-700' : 'text-white'
                            } px-1 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                        >
                          Kontakte
                        </a>
                      </Link>
                      <Link href="/affiliate">
                        <a
                          className={`${filled ? 'text-gray-700' : 'text-white'
                            } px-1 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                        >
                          Empfehlungsprogramm
                        </a>
                          </Link>*/}
                      <a
                        href="/api/auth/logout"
                        className={`${
                          filled ? "text-gray-700" : "text-white"
                        } px-1 py-2 text-sm font-medium border-b-2 border-transparent hover:border-green-400`}
                      >
                        Logout
                      </a>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${menuOpen ? "block" : "hidden"} bg-white sm:hidden border-t`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/*<!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->*/}
            <Link href="/">
              <a href="/" className="bg-green-400 text-white block px-3 py-2 rounded-md text-base font-medium">
                Übersicht
              </a>
            </Link>
            <Link href="/sendungsverfolgung">
              <a
                href="/sendungsverfolgung"
                className="text-gray-700 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Sendungsverfolgung
              </a>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export { Header };
