import React, { useEffect, useState } from "react";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import Link from "next/link";
import { gql, useMutation } from "@apollo/client";
import {
  subscribeNewsletter,
  subscribeNewsletterVariables,
} from "../__generated__/subscribeNewsletter";
import { useLocalStorage } from "react-use";
import { useRenderClientSideOnly } from "../lib/util";

interface FooterProps {}

const SUBSCRIBE_NEWSLETTER = gql`
  mutation subscribeNewsletter($email: String!) {
    subscribeNewsletter(email: $email)
  }
`;

const Footer: React.FC<FooterProps> = ({}) => {
  const [email, setEmail] = useState("");
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [subscribed, setSubribed] = useLocalStorage(
    "subscribed-newsletter",
    false
  );

  /*useEffect(() => {
    setShowSubscribe(subscribed);
  }, [subscribed]);*/

  const [subscribe, { data, error }] = useMutation<
    subscribeNewsletter,
    subscribeNewsletterVariables
  >(SUBSCRIBE_NEWSLETTER, { errorPolicy: "all" });

  return (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pt-12 pb-24 px-4 sm:px-6 lg:pt-16 lg:pb-24 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Tierversand
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/gefluegel-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Tauben Versenden
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/gefluegel-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Geflügel Versenden
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/saeugetiere-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Säugetiere Versenden
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Tierversand
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/reptilien-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Reptilien Versenden
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/fische-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Fische Versenden
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/insekten-versenden">
                      <a className="text-base text-gray-300 hover:text-white">
                        Insekten Versenden
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Über uns
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/bewertungen">
                      <a className="text-base text-gray-300 hover:text-white">
                        Bewertungen
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Rechtliches
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/impressum">
                      <a className="text-base text-gray-300 hover:text-white">
                        Impressum
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/agb-s">
                      <a className="text-base text-gray-300 hover:text-white">
                        AGB
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/datenschutz">
                      <a className="text-base text-gray-300 hover:text-white">
                        Datenschutz
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Sprache
            </h3>
            <form className="mt-4 sm:max-w-xs">
              <fieldset className="w-full">
                <label htmlFor="language" className="sr-only">
                  Language
                </label>
                <div className="relative">
                  <select
                    id="language"
                    name="language"
                    className="appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md py-2 pl-3 pr-10 text-base text-white focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                  >
                    <option>Deutsch</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="w-3 h-3 text-white"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div
          className={`${
            showSubscribe ? "hidden" : "lg:flex"
          } hidden border-t border-gray-700 pt-8 lg:items-center lg:justify-between xl:mt-0`}
        >
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Informieren und Sparen
            </h3>
            <p className="mt-2 text-base text-gray-300">
              Jeder Abonent unseres Newsletters erhält als Dankeschön einen 5%
              Gutschein für den nächsten Versand.
            </p>
          </div>
          {error ? (
            <div>
              <div>&nbsp;</div>
              <div className="text-red-500">Fehler beim eingetragen</div>
            </div>
          ) : data?.subscribeNewsletter ? (
            <div>
              <div>&nbsp;</div>
              <div className="text-green-400">Erfolgreich eingetragen</div>
            </div>
          ) : (
            <form
              className="mt-4 sm:flex sm:max-w-md lg:mt-0"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                subscribe({ variables: { email } });
                localStorage.setItem("subscribed-newsletter", "true");
                return false;
              }}
            >
              <label htmlFor="emailAddress" className="sr-only">
                Email Addresse
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
                placeholder="Email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  Anmelden
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-3">
            <a
              href="https://www.facebook.com/Tierversand24-100519408526880"
              target="_blank"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Facebook</span>
              <FontAwesomeIcon icon={faFacebook} className="text-2xl w-6 h-6" />
            </a>
            <a
              href="https://g.page/Tierversand24?gm"
              target="_blank"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Facebook</span>
              <FontAwesomeIcon icon={faGoogle} className="text-2xl w-6 h-6" />
            </a>
          </div>
          <div className="mt-4 md:mt-0 flex flex-wrap space-x-1 md:order-2 -ml-1 md:ml-2 md:mr-3">
            <div className="ml-1">
              <Image
                alt="PayPal"
                src="/img/payment/paypal@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Sofort"
                src="/img/payment/sofort@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Kreditkarte / Credit Card"
                src="/img/payment/creditcard@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Überweisung / Banktransfer"
                src="/img/payment/banktransfer@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Giropay"
                src="/img/payment/giropay@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Applepay"
                src="/img/payment/applepay@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="ING Homepay"
                src="/img/payment/inghomepay@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Bancontact"
                src="/img/payment/bancontact@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Belfius"
                src="/img/payment/belfius@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="EPS"
                src="/img/payment/eps@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="IDEAL"
                src="/img/payment/ideal@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="KBC"
                src="/img/payment/kbc@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Przelewy24"
                src="/img/payment/Przelewy24@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Voucher"
                src="/img/payment/Voucher@2x.png"
                width="32px"
                height="24px"
              />
            </div>
            <div>
              <Image
                alt="Giftcard"
                src="/img/payment/giftcard@2x.png"
                width="32px"
                height="24px"
              />
            </div>
          </div>
          <p className="mt-3 flex-shrink-0 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; Tierversand24 eine Marke von Gardow Logistics
          </p>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
